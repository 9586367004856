import React, { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// @ld
import { Loader } from '@ld/core';
// @custom
import Logo from '../logo';
import Footer from '../footer';
import ValueTabs from './value-tabs';
import useRedirectToApp from './use-redirect-to-app';
import { AdvisorPortalText, LogoGroupWidth } from 'pages/auth/styles';
// @local
import {
  Main,
  AuthMain,
  AuthChildren,
  AuthContainer,
  ShapesBackground,
  AuthChildrenContainer
} from './styles';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContainer>
      <ShapesBackground />
      <Main>
        <AuthMain>
          <AuthChildrenContainer>
            <AuthChildren>
              <LogoGroupWidth>
                <Logo />
                <AdvisorPortalText>advisor portal</AdvisorPortalText>
              </LogoGroupWidth>
              {children}
            </AuthChildren>
            <Footer />
          </AuthChildrenContainer>
          <ValueTabs />
        </AuthMain>
      </Main>
    </AuthContainer>
  );
};

const Fallback = () => (
  <Box display='flex' alignItems='center' justifyContent='center' p={3}>
    <Loader />
  </Box>
);

const AuthLayoutRoute = ({
  component: Component,
  location,
  children,
  ...rest
}: RouteProps & { children?: React.ReactNode }) => {
  useRedirectToApp();
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout>
          <Suspense fallback={<Fallback />}>
            {Component ? <Component {...props} /> : children}
          </Suspense>
        </AuthLayout>
      )}
    />
  );
};

export default React.memo(AuthLayoutRoute);
