export const getProfilesUrl = () => `/profiles`;

export const getProfileUrl = (profileId = '') => `${getProfilesUrl()}/${profileId}`;

export const getVaultUrl = (profileId = '') => `${getProfileUrl(profileId)}/vault`;

export const getVaultSetupUrl = (profileId = '') =>
  `${getProfileUrl(profileId)}/vault/setup`;

export const getVaultManagerUrl = (profileId = '') =>
  `${getProfileUrl(profileId)}/vault/manage`;

type VaultCategoryParams = {
  profileId?: string;
  categoryId?: string;
};

export const getCategoryUrl = ({ profileId, categoryId }: VaultCategoryParams) =>
  `${getVaultUrl(profileId)}/c/${categoryId}`;

export const getManageCategoryUrl = (params: VaultCategoryParams) =>
  `${getCategoryUrl(params)}/manage`;

export const getCategoryDocTypeUrl = ({
  typeId,
  ...rest
}: VaultCategoryParams & { typeId?: string }) => `${getCategoryUrl(rest)}/t/${typeId}`;

type EntityUrlParams = VaultCategoryParams & { entityId?: string };

export const getEntityUrl = ({ entityId, ...rest }: EntityUrlParams) =>
  `${getCategoryUrl(rest)}/e/${entityId}`;

export const getManageEntityUrl = (params: EntityUrlParams) =>
  `${getEntityUrl(params)}/manage`;

type EntityDocTypeUrlParams = EntityUrlParams & { typeId?: string };

export const getEntityDocTypeUrl = ({ typeId, ...rest }: EntityDocTypeUrlParams) =>
  `${getEntityUrl(rest)}/t/${typeId}`;

export const getDocumentTypeUrl = (params: EntityDocTypeUrlParams) => {
  if (params.entityId) {
    return getEntityDocTypeUrl(params);
  }
  return getCategoryDocTypeUrl(params);
};

type DocumentParams = EntityDocTypeUrlParams & {
  id: string;
  multiple?: boolean;
};

export const getDocumentUrl = ({
  id,
  typeId,
  entityId,
  multiple = false,
  ...baseParams
}: DocumentParams) => {
  let baseUrl = '';
  if (multiple) {
    baseUrl = getDocumentTypeUrl({ ...baseParams, typeId });
  }

  if (entityId) {
    baseUrl = getEntityUrl({ ...baseParams, entityId });
  } else {
    baseUrl = getCategoryUrl(baseParams);
  }

  return `${baseUrl}/d/${id}`;
};
