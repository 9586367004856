import { getAuth } from 'firebase/auth';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { query, where, addDoc, FirestoreError } from 'firebase/firestore/lite';
// @ld
import { EProfileType, IProfileRead, IProfileWrite, EProfileRole } from '@ld/utils';
// @custom
import useUser from 'hooks/use-user';
import getEventAt from 'utils/get-event-at';
// @local
import { getCollectionRef } from './utils/refs';
import { itemQueryKey } from './utils/use-get-item';

export const PROFILES = 'profiles';

export const useCreateProfile = (
  orgId: string,
  options?: UseMutationOptions<
    IProfileRead,
    FirestoreError,
    Pick<IProfileWrite, 'name' | 'email' | 'status'>
  >
) => {
  const { user } = useUser();
  const { mutate: handleCreateProfile, ...rest } = useMutation(async (data) => {
    if (user) {
      const eventAt = getEventAt(user);
      const profileWrite: IProfileWrite = {
        photoURL: null,
        name: data.name,
        promoCode: null,
        countryCode: 'za',
        isVaultSetup: false,
        status: data.status,
        billingUserId: null,
        paymentMethodId: null,
        estateReadinessScore: 0,
        estateReadinessGap: null,
        type: EProfileType.PERSONAL,
        estateReadinessGapCount: 0,
        billingOrganisationId: orgId,
        suspendedReason: null,
        email: data.email?.trim()?.toLowerCase() || null,
        roles: { [user.uid]: EProfileRole.OWNER },
        created: eventAt.write,
        updated: eventAt.write
      };

      return addDoc(getCollectionRef(PROFILES), profileWrite).then(
        (docRef) =>
          ({
            id: docRef.id,
            ...profileWrite,
            created: eventAt.read,
            updated: eventAt.read
          } as IProfileRead)
      );
    } else {
      throw Error('Only authenticated users can perform this action');
    }
  }, options);
  return { handleCreateProfile, ...rest };
};

export const profileQueryKey = (profileId?: string) => itemQueryKey(PROFILES, profileId);

export const profilesQueryKey = (uid?: string) => ['users', uid, PROFILES];

export const profilesQuery = () => {
  const user = getAuth().currentUser;
  return query(
    getCollectionRef(PROFILES),
    where(`roles.${user?.uid}`, 'in', [
      EProfileRole.OWNER,
      EProfileRole.EDITOR,
      EProfileRole.WRITER,
      EProfileRole.READER
    ])
  );
};
