// @ld
import { colors } from '@ld/utils';
import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';

export const ASide = styled.aside`
  background: #ffffff;
  padding-top: 16px;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-top: 10px;
`;

export const Footer = styled.div`
  display: block;
`;

export const Button = styled(MuiButton)`
  && {
    display: flex;
    padding: 20px 30px;
    border-radius: 0px;
    font-weight: normal;
    color: ${colors.black1};
    background: transparent;
    justify-content: flex-start;
    background-color: transparent;

    .MuiButton-startIcon {
      margin-left: 0px;
      margin-right: 15px;
    }

    .MuiButton-iconSizeMedium > *:first-of-type {
      font-size: 16px;
    }

    &:hover {
      color: ${colors.orange1};
      background: #ffffff;
    }
  }
`;

export const OrganisationLogo = styled.img`
  width: 180px;
  height: auto;
`;

export const PoweredByLink = styled.a`
  &,
  &:link,
  &:hover,
  &:active,
  &:visited {
    display: flex;
    padding: 5px;
    margin-top: 10px;
    align-items: center;
    border-radius: 50px;
    border: 1px solid ${colors.grey5};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    color: ${colors.black1};
    text-decoration: none;
  }
`;
