import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @custom
import {
  profilesUrl,
  verifyEmailUrl,
  unauthorizedUrl,
  twoFactorAuthenticationUrl
} from 'constants/urls';
import {
  getIsTwoFactorVerified,
  getWasSigninEmailNotificationSent,
  setSigninEmailNotificationSentFlag
} from 'services/session-storage';
import useUser from 'hooks/use-user';
import { useSendSignInNotificationEmail } from 'api/auth';

/**
 * Used to redirect away from auth screens if the user is successfully logged in
 */
const useRedirectToApp = () => {
  const { user } = useUser();
  const history = useHistory();

  const { handleSendSignInNotificationEmail } = useSendSignInNotificationEmail({
    onSuccess: () => {
      setSigninEmailNotificationSentFlag();
    }
  });

  useEffect(() => {
    if (user) {
      if (!getWasSigninEmailNotificationSent()) {
        // Don't send emails on staging or debug env
        handleSendSignInNotificationEmail();
      }
      if (!user.isEmailVerified) {
        history.replace({
          pathname: verifyEmailUrl,
          state: { email: user.email }
        });
      } else if (user.isTwoFactorEnabled && !getIsTwoFactorVerified()) {
        history.replace(twoFactorAuthenticationUrl);
      } else if (user.advisor) {
        history.replace(profilesUrl);
      } else {
        history.replace(unauthorizedUrl);
      }
    }
  }, [history, user]);

  return null;
};

export default useRedirectToApp;
