import { NavLinkProps } from 'react-router-dom';
import {
  IoPeople,
  IoPeopleOutline,
  IoPeopleCircleOutline,
  IoPeopleCircle,
  IoPersonCircle,
  IoPersonCircleOutline,
  IoBusinessOutline,
  IoBusiness,
  IoHelpCircle,
  IoHelpCircleOutline
} from 'react-icons/io5';

// @custom
import {
  profilesUrl,
  advisorsUrl,
  myAccountUrl,
  organisationUrl,
  helpAndSupportUrl
} from 'constants/urls';
import { StyledLink, IconWrapper, ActiveBorder } from './styles';

const StyledNavLink = ({ children, ...rest }: NavLinkProps) => (
  <StyledLink {...rest} activeClassName='active'>
    <ActiveBorder />
    {children}
  </StyledLink>
);

const Profiles = () => (
  <StyledNavLink to={profilesUrl}>
    <IconWrapper>
      <IoPeopleOutline size={24} />
      <IoPeople size={24} />
    </IconWrapper>
    Profiles
  </StyledNavLink>
);

const Advisors = () => (
  <StyledNavLink to={advisorsUrl}>
    <IconWrapper>
      <IoPeopleCircleOutline size={24} />
      <IoPeopleCircle size={24} />
    </IconWrapper>
    Advisors
  </StyledNavLink>
);

const Organisation = () => (
  <StyledNavLink to={organisationUrl}>
    <IconWrapper>
      <IoBusinessOutline size={24} />
      <IoBusiness size={24} />
    </IconWrapper>
    Organisation
  </StyledNavLink>
);

const Account = () => (
  <StyledNavLink to={myAccountUrl}>
    <IconWrapper>
      <IoPersonCircleOutline size={24} />
      <IoPersonCircle size={24} />
    </IconWrapper>
    Account
  </StyledNavLink>
);

export const HelpAndSupport = () => (
  <StyledNavLink to={helpAndSupportUrl}>
    <IconWrapper>
      <IoHelpCircleOutline size={24} />
      <IoHelpCircle size={24} />
    </IconWrapper>
    Help &amp; support
  </StyledNavLink>
);

export default [
  { allow: ['super', 'admin', 'member'], component: Profiles },
  { allow: ['super'], component: Advisors },
  { allow: ['super'], component: Organisation },
  { allow: ['super', 'admin', 'member'], component: Account }
];
