import { Link } from 'react-router-dom';
// @mui
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// @ld
import { BodyLargeText } from '@ld/core';
// @components
import Seo from 'components/seo';

function PageNotFound() {
  return (
    <Container maxWidth='sm'>
      <Seo title='404 - Page not found' />
      <Grid
        container
        style={{ minHeight: '100vh' }}
        component='main'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item>
          <BodyLargeText align='center'>
            Page not found, do you want to <Link to='/'>Go back</Link>?
          </BodyLargeText>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PageNotFound;
