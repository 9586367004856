import * as React from 'react';
// @ld
import {
  TDocumentUpload,
  TDocumentUploadOptions,
  TDocumentUploadProcessing
} from './types';

type TDocumentsUploadServiceContext = {
  isLoading: boolean;
  uploadProcessingData: TDocumentUploadProcessing[];
  handleDocumentUpload: (data: TDocumentUpload, options?: TDocumentUploadOptions) => void;
};

export const DocumentsUploadServiceContext = React.createContext(
  {} as TDocumentsUploadServiceContext
);

export const useDocumentUploadService = () => {
  return React.useContext(DocumentsUploadServiceContext);
};
