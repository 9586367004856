import { colors } from '@ld/utils';
import styled from '@emotion/styled';

export const StyledFooter = styled.footer`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${colors.black1};
`;
