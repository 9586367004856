import React from 'react';
// @mui
import { Box, ListItem, Typography, ListItemIcon, ListItemText } from '@mui/material';
// @ld
import {
  TimeIcon,
  HeartBeatOutlinedIcon,
  AlarmClockIcon,
  DocumentIcon,
  PeopleIcon,
  UmbrellaIcon,
  ShieldOutlinedIcon,
  CloudCheckedIcon,
  ArrowIcon,
  Heading3
} from '@ld/core';
// @custom
import { ValueDisplay, AuthValueContainer, ValueDisplayWithoutIcon } from './styles';
import {
  ValueTab,
  ValueList,
  ValueRoot,
  ValueIconBoxed,
  ValueTabsParent
} from 'pages/auth/styles';
import { ReactComponent as AdvisorsValueImg } from '../../assets/advisors.svg';
import { ReactComponent as CustomersValueImg } from '../../assets/customers.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`value-tabpanel-${index}`}
      aria-labelledby={`value-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `value-tab-${index}`,
    'aria-controls': `value-tabpanel-${index}`
  };
}

const advisorsValue = [
  {
    icon: TimeIcon,
    value:
      'Get clients estate ready in half the time it takes to prepare a manual life file.'
  },
  {
    icon: HeartBeatOutlinedIcon,
    value:
      'Ensure client estates are ready to be wound up at any stage with our Lifedocs Estate Readiness Score.'
  },
  {
    icon: AlarmClockIcon,
    value:
      'Automated reminders to review and update documents or estate cover at every life stage.'
  },
  {
    icon: DocumentIcon,
    value: `Faster estate execution with instant access to customer data upon death. `
  },
  {
    icon: PeopleIcon,
    value: 'Easier identification and contacting of legacy heirs/contacts.'
  }
];

const customersValue = [
  {
    icon: UmbrellaIcon,
    value: 'Help them organise their lives and have peace of mind.'
  },
  {
    icon: ShieldOutlinedIcon,
    value:
      'Their personal data and documents are safe and secure with bank-grade encryption.'
  },
  {
    icon: CloudCheckedIcon,
    value: 'Family documents are up to date and accessible 24/7 in the cloud.'
  },
  {
    icon: ArrowIcon,
    value: 'Seamless transfer of estate information to the family upon death.'
  }
];

export default function ValueTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: React.SetStateAction<number>
  ) => {
    event.preventDefault();
    setValue(newValue);
  };
  return (
    <AuthValueContainer>
      <ValueRoot>
        <ValueTabsParent
          value={value}
          onChange={handleChange}
          aria-label='advisors and customer value tabs'
        >
          <ValueTab label='Value to advisors' {...a11yProps(0)} />
          <ValueTab label='Value to customers' {...a11yProps(1)} />
        </ValueTabsParent>
        <TabPanel value={value} index={0}>
          <ValueDisplay>
            <ValueDisplayWithoutIcon>
              <Box my={2}>
                <Heading3>Our value to advisors</Heading3>
              </Box>
              <ValueList>
                {advisorsValue.map(({ icon: ValueIcon, value }, index) => {
                  return (
                    <ListItem key={`advisor-value-${index}`}>
                      <ListItemIcon>
                        <ValueIconBoxed>
                          <ValueIcon />
                        </ValueIconBoxed>
                      </ListItemIcon>
                      <ListItemText primary={value} />
                    </ListItem>
                  );
                })}
              </ValueList>
            </ValueDisplayWithoutIcon>
            <AdvisorsValueImg />
          </ValueDisplay>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValueDisplay>
            <ValueDisplayWithoutIcon>
              <Box my={2}>
                <Heading3>Our value to your customers</Heading3>
              </Box>
              <ValueList>
                {customersValue.map(({ icon: ValueIcon, value }, index) => {
                  return (
                    <ListItem key={`customer-value-${index}`}>
                      <ValueIconBoxed>
                        <ValueIcon />
                      </ValueIconBoxed>
                      <ListItemText primary={value} />
                    </ListItem>
                  );
                })}
              </ValueList>
            </ValueDisplayWithoutIcon>
            <CustomersValueImg />
          </ValueDisplay>
        </TabPanel>
      </ValueRoot>
    </AuthValueContainer>
  );
}
