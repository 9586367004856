export const signinUrl = '/';
export const twoFactorAuthenticationUrl = '/two-factor';
export const createAccountUrl = '/create-an-account';
export const createAccountSuccessUrl = '/create-account-success';
export const forgotPasswordUrl = '/forgot-password';
export const verifyEmailUrl = '/verify-email';
export const authActionUrl = '/auth-action';
export const profilesUrl = '/profiles';
export const advisorsUrl = '/advisors';
export const organisationUrl = '/organisation';
export const unauthorizedUrl = '/unauthorized';
export const helpAndSupportUrl = '/help-and-support';
export const lockAccountUrl = '/lock-account/:token';
export const lockedAccountUrl = '/locked-account';

// account and settings
export const myAccountUrl = '/my-account';
