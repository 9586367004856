import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// @mui
import {
  Tab,
  Tabs,
  List,
  ListItemIcon,
  ListItemText,
  Chip as MuiChip
} from '@mui/material';
// @ld
import { colors } from '@ld/utils';
// import { FormField } from '@ld/core';

export const Content = styled.div`
  padding: 30px;
`;

export const FormWrapper = styled.div`
  display: flex;
  margin: 64px 32px;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  background-color: ${colors.green3};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Chip = styled(MuiChip)`
  && {
    max-width: 240px;
    line-height: 1.2;
    background: ${colors.grey5};
  }
`;

export const DisclaimerWrapper = styled.div`
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: ${colors.grey5};
`;

export const WarningBox = styled.div`
  padding: 20px;
  margin: 20px 0px;
  border-radius: 10px;
  background: ${colors.grey5};

  ul {
    color: ${colors.orange1};
    padding-left: 15px;

    li {
      padding: 5px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const PrimaryStyledLink = styled(Link)`
  &,
  &:active,
  &:hover,
  &:link {
    text-decoration: none;
    color: ${colors.orange1};
  }
`;

export const ExternalLink = styled.a<{ color: 'primary' | 'secondary' }>`
  &,
  &:active,
  &:hover,
  &:link {
    text-decoration: none;
    color: ${(props: any) =>
      props.color === 'secondary' ? colors.green1 : colors.orange1};
  }
`;

export const SignInText = styled.p`
  color: white;
  font-weight: 700;
  font-size: 26px;
  line-height: 31.69px;
  margin-top: 41px;
`;

export const LogoGroupWidth = styled.div`
  width: 229px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const AdvisorPortalText = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${colors.grey1};
  text-align: right;
  margin-right: 24px;
`;

export const ValueRoot = styled.div`
  flex-grow: 1;
  background-color: transparent;
`;

export const ValueTabsParent = styled(Tabs)`
  && {
    flex-grow: 1;
    background-color: transparent;
  }
`;

export const ValueTab = styled(Tab)`
  & .MuiTab-Wrapper {
    color: ${colors.green1};
    text-transform: none;
  }
`;

export const ValueListItemText = styled(ListItemText)`
  line-height: 24px;
  font-size: 16px;
`;

// export const AuthFormField = styled(FormField)`
//   & .MuiFormLabel-root,
//   & .MuiIconButton-root,
//   & .MuiInputBase-root,
//   & .MuiSelect-iconOutlined {
//     color: white;
//   }

//   & .MuiOutlinedInput-notchedOutline {
//     color: ${colors.grey1};
//     border-color: ${colors.grey2};
//     &:hover {
//       color: ${colors.grey1};
//       border-color: ${colors.grey2};
//     }
//   }
// `;

export const ValueIconBox = styled.div`
  & .MuiSvgIcon-root {
    fill: ${colors.black1};
    width: 22.5px;
    height: 22.5px;
  }
`;

export const ValueIconBoxed = styled(ListItemIcon)`
  & .MuiSvgIcon-root {
    fill: white;
    width: 24px;
    height: 24px;
    stroke: black;
    stroke-width: 1px;
  }
`;
export const ValueList = styled(List)`
  & .MuiListItemIcon-root {
    min-height: 50px;
    min-width: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.orange1};
`;
