import * as React from 'react';
// @local
import useUser from 'hooks/use-user';
import useProfile from 'hooks/use-profile';
import { AbilityContext, buildAbilityFor, TAccessAbility } from '@ld/core';

export const ProfileAccessProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const { profile } = useProfile();
  const [ability, setAbility] = React.useState<TAccessAbility>(buildAbilityFor('none'));
  React.useEffect(() => {
    if (profile && user) {
      const advisorId = user.uid || '';
      const newAbility = buildAbilityFor(profile.roles[advisorId]);
      setAbility(newAbility);
    }
  }, [profile, user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
