import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @custom
import useUser from 'hooks/use-user';
import { verifyEmailUrl } from 'constants/urls';

/**
 * Used to redirect users that don't have mission critical data for
 * them to use the app. This obviously includes not being logged in.
 */
const useAuthRedirect = ({
  redirectIfNotOnboarded = true,
  location
}: {
  redirectIfNotOnboarded?: boolean;
  location?: any;
}) => {
  const { user } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      const nextUrl = location?.pathname ? `?nextUrl=${location.pathname}` : '';
      history.replace(`/${nextUrl}`);
    } else if (!user.isEmailVerified) {
      history.replace({
        pathname: verifyEmailUrl,
        state: { email: user.email }
      });
    }
  }, [history, location.pathname, redirectIfNotOnboarded, user]);

  return null;
};

export default useAuthRedirect;
