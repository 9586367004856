import * as React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// @ld
import { IAdvisorRead } from '@ld/utils';
// @custom
import useAuth from 'hooks/use-auth';
import UserContext from 'context/user-context';
import { ProfileProvider } from 'context/profile-context';
import { SnackbarProvider } from 'context/snackbar-context';
import { ProfileAccessProvider } from 'context/profile-access-context';
import { DocumentsUploadServiceProvider } from 'components/document-upload-service';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const { user, isInitialising, setUser } = useAuth();

  if (isInitialising) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <UserContext.Provider
          value={{
            user,
            setUser: (newUser: IAdvisorRead | null) => setUser(newUser)
          }}
        >
          <ProfileProvider>
            <DocumentsUploadServiceProvider>
              <ProfileAccessProvider>{children}</ProfileAccessProvider>
            </DocumentsUploadServiceProvider>
          </ProfileProvider>
        </UserContext.Provider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppProvider;
