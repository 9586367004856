// two factor
export const VERIFIED_KEY = 'ldvk-nbdsvbh98y3489';
export const VERIFIED_VALUE = 'LD:bfdw86ffbfr7frgur4f8';

export const setTwoFactorVerified = () => {
  window.sessionStorage.setItem(VERIFIED_KEY, VERIFIED_VALUE);
};

export const getIsTwoFactorVerified = () => {
  return window.sessionStorage.getItem(VERIFIED_KEY) === VERIFIED_VALUE;
};

export const removeTwoFactorVerified = () => {
  window.sessionStorage.removeItem(VERIFIED_KEY);
};

// signin email notification
export const SIGNIN_NOTIFICATION_EMAIL_KEY = 'ldsne-f71633070ac4';
export const SIGNIN_NOTIFICATION_EMAIL_VALUE = 'LD:e81fac4b6f87d77a8376f44e';

export const setSigninEmailNotificationSentFlag = () => {
  window.sessionStorage.setItem(
    SIGNIN_NOTIFICATION_EMAIL_KEY,
    SIGNIN_NOTIFICATION_EMAIL_VALUE
  );
};

export const getWasSigninEmailNotificationSent = () => {
  return (
    window.sessionStorage.getItem(SIGNIN_NOTIFICATION_EMAIL_KEY) ===
    SIGNIN_NOTIFICATION_EMAIL_VALUE
  );
};

export const removeSigninEmailNotificationSentFlag = () => {
  window.sessionStorage.removeItem(SIGNIN_NOTIFICATION_EMAIL_KEY);
};
