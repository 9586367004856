import { useState, useEffect } from 'react';
import {
  ref,
  getStorage,
  UploadTask,
  getDownloadURL,
  uploadBytesResumable
} from 'firebase/storage';
// @ld
import { EUploadFileStatus } from '@ld/utils';
import { getAutoRenamedFile } from 'utils/file-helpers';

type UseUploadFileData = {
  path: string;
  file: File;
  autoRenameFile?: boolean;
};

type UseUploadFileOptions = {
  enabled?: boolean;
  onSuccess?: ({
    fileName,
    downloadURL
  }: {
    fileName: string;
    downloadURL: string;
  }) => void;
  onError?: (error: Error) => void;
};

const useUploadFile = (
  uploadData?: UseUploadFileData,
  options: UseUploadFileOptions = {}
) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<EUploadFileStatus>(EUploadFileStatus.IDLE);

  const handleUploadFile = (
    { path = '', file, autoRenameFile = true }: UseUploadFileData,
    { onError, onSuccess }: UseUploadFileOptions = {}
  ) => {
    // Create a root reference
    const storage = getStorage();
    const finalFile = autoRenameFile ? getAutoRenamedFile(file) : file;
    const fileName = finalFile.name;
    // Create a reference to given path
    const storageRef = ref(storage, `${path}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            setStatus(EUploadFileStatus.PAUSED);
            break;
          case 'running':
            setStatus(EUploadFileStatus.UPLOADING);
            break;
        }
      },

      (error) => {
        // Handle unsuccessful uploads
        setStatus(EUploadFileStatus.ERROR);
        console.error(error);
        if (onError) onError(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        setStatus(EUploadFileStatus.SUCCESS);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (onSuccess) onSuccess({ fileName, downloadURL });
        });
      }
    );

    return uploadTask;
  };

  useEffect(() => {
    let uploadTask: UploadTask;
    if (options?.enabled && uploadData && status === EUploadFileStatus.IDLE) {
      uploadTask = handleUploadFile(uploadData, options);
    }
    return () => {
      uploadTask?.cancel();
    };
  }, [options?.enabled]);

  return {
    status,
    progress,
    handleUploadFile,
    isIdle: status === EUploadFileStatus.IDLE,
    isError: status === EUploadFileStatus.ERROR,
    isSuccess: status === EUploadFileStatus.SUCCESS,
    isUploading: status === EUploadFileStatus.UPLOADING
  };
};

export default useUploadFile;
