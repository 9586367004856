import { useState, useEffect } from 'react';
import { onSnapshot, getFirestore, doc } from 'firebase/firestore';

type UseDocListenerOptions<TData> = {
  id?: string;
  enabled?: boolean;
  collection: string;
  onError?: (error: Error) => void;
  onChange?: (data: TData) => void;
};

enum EDocListenerStatus {
  IDLE = 'idle',
  ERROR = 'error',
  ACTIVE = 'active'
}

const useDocListener = <TDataRead = unknown>({
  id,
  onError,
  onChange,
  collection,
  enabled = true
}: UseDocListenerOptions<TDataRead>) => {
  const [data, setData] = useState<TDataRead>();
  const [status, setStatus] = useState<EDocListenerStatus>(EDocListenerStatus.IDLE);

  useEffect(() => {
    if (enabled && id) {
      const db = getFirestore();
      const docRef = doc(db, collection, id);
      setData(undefined);
      return onSnapshot(
        docRef,
        (doc) => {
          const docData = { id: doc.id, ...doc.data() } as TDataRead;
          setData(docData);
          onChange?.(docData);
          setStatus(EDocListenerStatus.ACTIVE);
        },
        (error) => {
          console.error(error);
          onError?.(error);
          setStatus(EDocListenerStatus.ERROR);
        }
      );
    }
    return;
  }, [enabled, id]);

  return {
    data,
    isIdle: status === EDocListenerStatus.IDLE,
    isError: status === EDocListenerStatus.ERROR,
    isActive: status === EDocListenerStatus.ACTIVE
  };
};

export default useDocListener;
